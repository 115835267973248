import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { usePermissionsOptions } from 'hooks';

type SelectPermissionsProps = SelectProps;

const SelectPermissions = ({ ...props }: SelectPermissionsProps) => {
  const { permissions, handleLoadMore, handleSearch, isLoading } = usePermissionsOptions();
  return (
    <Select
      {...props}
      onLoadMore={() => {
        if (!isLoading) {
          handleLoadMore();
        }
      }}
      loading={isLoading}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={
        permissions.map((itm) => ({
          label: itm.name,
          value: itm.permissionId
        })) || []
      }
    />
  );
};
export default SelectPermissions;
