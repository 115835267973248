import { FormProps } from 'antd';
import { loginMessages } from './login.messages';
import { organizationUnitsMessages } from './organization-units.messages';
import { permissionsMessages } from './permissions.messages';
import { profileMessages } from './profile.messages';
import { rolesMessages } from './roles.messages';
import { rulesMessages } from './rules.messages';
import { sidebarMenuMessages } from './sidebar-menu.messages';
import { userGroupsMessages } from './user-groups.messages';

export const messages = {
  appName: 'CRM v2 TT CSKH',
  home: 'Trang chủ',
  login: 'Đăng nhập',
  loginSuccess: 'Đăng nhập thành công!',
  logout: 'Đăng xuất',
  pageNotFound: 'Xin lỗi, trang bạn truy cập không tồn tại.',
  somethingWentWrong: 'Xin lỗi, đã có lỗi xảy ra.',
  loadingPage: 'Đang tải trang...',
  loadingProfile: 'Đang tải thông tin người dùng...',
  profile: 'Hồ sơ cá nhân',
  changeAvatar: 'Đổi ảnh đại diện',
  deleteAvatar: 'Xóa ảnh đại diện',
  confirmButtonText: 'Xác nhận',
  saveButtonText: 'Lưu',
  cancelButtonText: 'Hủy',
  createButtonText: 'Tạo mới',
  datePickerPlaceHolder: 'Ngày tháng năm',
  userPage: 'Nhân viên',
  fileNotCorrectFormat: 'File không đúng định dạng',
  sidebarMenuMessages,
  profileMessages,
  rulesMessages,
  organizationUnitsMessages,
  userGroupsMessages,
  loginMessages,
  rolesMessages,
  permissionsMessages
};
export const validateMessages: FormProps['validateMessages'] = {
  /* eslint-disable no-template-curly-in-string */
  required: 'Vui lòng nhập ${label}',
  string: {
    max: '${label} không được quá ${max} ký tự'
  },
  number: {
    min: '${label} phải tối thiểu ${min}'
  },
  types: {
    email: '${label} không đúng định dạng'
  }
  /* eslint-enable no-template-curly-in-string */
};
