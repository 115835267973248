import { Form, FormInstance, Input, notification, Skeleton } from 'antd';
import { Rule } from 'antd/es/form';
import { SelectOrganizationUnits } from 'components/organization-units';
import { SelectPermissions } from 'components/permissions';
import { messages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useCreateUserGroupMutation,
  useGetUserGroupDetailQuery,
  useUpdateUserGroupMutation
} from 'services/user-groups';
import { CreateUserGroupDto } from 'types';
export type UserGroupFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  userGroupId?: number;
};

export type UserGroupFormRefProps = {
  form: FormInstance<CreateUserGroupDto>;
  isLoading: boolean;
};

type UserGroupFormType = CreateUserGroupDto;
const UserGroupForm = forwardRef<UserGroupFormRefProps, UserGroupFormProps>(
  ({ onChangeLoading, onCreateSuccess, userGroupId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingCreate || isLoadingUpdate
    }));

    const [form] = Form.useForm<UserGroupFormType>();

    const { data: userGroup, isLoading: isLoadingDetail } = useGetUserGroupDetailQuery(userGroupId!, {
      skip: !userGroupId
    });

    const [onCreate, { isLoading: isLoadingCreate }] = useCreateUserGroupMutation();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateUserGroupMutation();

    useEffect(() => {
      if (userGroup && userGroupId) {
        form.setFieldsValue({
          ...userGroup.data,
          permissionIds: userGroup.data.permissions.map((o) => o.permissionId)
        });
      }
    }, [userGroup, userGroupId]);

    const onFinish = ({ ...values }: UserGroupFormType) => {
      const data: CreateUserGroupDto = {
        ...values
      };
      if (!userGroupId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            notification.success({
              message: rs.message
            });
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          userGroupId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            notification.success({
              message: rs.message
            });
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingCreate || isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

    const validationRules: Partial<Record<keyof UserGroupFormType, Rule[]>> = {
      name: [{ required: true }],
      organizationUnitId: [{ required: true }],
      permissionIds: [{ required: true }]
    };
    return (
      <Form
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name=''
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
      >
        <Skeleton loading={isLoadingDetail}>
          <Form.Item<UserGroupFormType> label={messages.userGroupsMessages.userGroupId} name='code'>
            <Input />
          </Form.Item>
          <Form.Item<UserGroupFormType>
            label={messages.userGroupsMessages.userGroupName}
            name='name'
            rules={validationRules.name}
          >
            <Input />
          </Form.Item>

          <Form.Item<UserGroupFormType>
            label={messages.userGroupsMessages.organizationUnit}
            name='organizationUnitId'
            rules={validationRules.organizationUnitId}
          >
            <SelectOrganizationUnits />
          </Form.Item>
          <Form.Item<UserGroupFormType>
            label={messages.userGroupsMessages.permission}
            name='permissionIds'
            rules={validationRules.permissionIds}
          >
            <SelectPermissions mode='multiple' />
          </Form.Item>
        </Skeleton>
      </Form>
    );
  }
);
export default UserGroupForm;
