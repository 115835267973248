import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useGetPermissionsQuery } from 'services';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function usePermissionsPaging() {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [keyword, setKeyword] = useState('');
  const {
    data: permissionsResponse,
    error,
    isLoading,
    isFetching
  } = useGetPermissionsQuery({
    keyword,
    pageIndex: pageIndex,
    pageSize
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  const debouncedKeywordChange = useCallback(
    debounce((keywordValue: string) => {
      setKeyword(keywordValue);
    }, 500),
    [setKeyword]
  );

  const handleSearch = (value: string) => {
    debouncedKeywordChange(value);
  };

  return {
    permissions: permissionsResponse?.data.rows || [],
    count: permissionsResponse ? permissionsResponse.data.count : 0,
    pageIndex,
    isLoading: isLoading || isFetching,
    error,
    handleChangePage,
    keyword,
    handleSearch
  };
}
