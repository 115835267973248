import { CaretDownFilled } from '@ant-design/icons';
import { Select as SelectAntd, SelectProps as SelectPropsAntd, Spin } from 'antd';
import { LoupIcon } from 'assets';
import { debounce } from 'lodash';
import { useCallback } from 'react';
export type SelectProps = SelectPropsAntd & {
  onLoadMore?: () => void;
};
const Select = ({ onSearch, onLoadMore, ...props }: SelectProps) => {
  const debouncedOnSearch = useCallback(
    debounce((keywordValue: string) => {
      onSearch?.(keywordValue);
    }, 500),
    [onSearch]
  );

  const handleSearch = (val: string) => {
    debouncedOnSearch(val);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    console.log('loadmore');
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      onLoadMore?.();
    }
  };
  return (
    <SelectAntd
      {...props}
      onPopupScroll={handleScroll}
      onSearch={handleSearch}
      suffixIcon={
        props.loading ? (
          <Spin spinning />
        ) : props.showSearch ? (
          <LoupIcon className='text-colorTextPlaceholder' />
        ) : (
          <CaretDownFilled className='text-colorTextPlaceholder' />
        )
      }
    />
  );
};
export default Select;
