import { Layout, Typography } from 'antd';
import { Loadable, SidebarMenu, SidebarSubmenu } from 'components';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { RootState } from 'store';
import { LOCAL_STORAGE_KEY } from 'utils';

const { Header, Sider } = Layout;

function MainLayout() {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  if (!localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) || !isAuthenticated) {
    return <Navigate to={ROUTE.LOGIN} replace />;
  }

  return (
    <Layout>
      <Sider className='z-10' width={128}>
        <Layout className='sider-container'>
          <SidebarMenu />
          <SidebarSubmenu />
        </Layout>
      </Sider>
      <Layout>
        <Header className='flex items-center bg-white px-8'>
          <Typography.Title level={3} className='mb-0'></Typography.Title>
        </Header>
        <Layout className='h-[calc(100vh-64px)] max-h-[calc(100vh-64px)] overflow-y-auto px-8 py-6'>
          <Loadable>
            <Outlet />
          </Loadable>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
