import { Form, FormInstance, Input, notification, Skeleton } from 'antd';
import { DatePicker } from 'components/common';
import { FormItem } from 'components/common/form-item';
import { SelectOptions } from 'components/options';
import { SelectOrganizationUnits } from 'components/organization-units';
import { SelectPermissions } from 'components/permissions';
import { SelectUserGroups } from 'components/user-groups';
import { messages, validateMessages } from 'messages';
import { usersMessages } from 'messages/users.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useCreateUserMutation, useUpdateUserMutation } from 'services';
import { CreateUserDto } from 'types';
import { AUTH_OPTION_TYPES, MICROSERVICES } from 'utils';
export type UserFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  userId?: number;
};

export type UserFormRefProps = {
  form: FormInstance<CreateUserDto>;
  isLoading: boolean;
};

type UserFormType = CreateUserDto & {
  confirmPassword?: string;
};
const UserForm = forwardRef<UserFormRefProps, UserFormProps>(({ onChangeLoading, onCreateSuccess, userId }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingCreate || isLoadingUpdate
  }));

  const [form] = Form.useForm<UserFormType>();

  const [onCreate, { isLoading: isLoadingCreate }] = useCreateUserMutation();
  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateUserMutation();

  const onFinish = ({ ...values }: UserFormType) => {
    const data: CreateUserDto = {
      ...values
    };
    if (!userId) {
      onCreate(data)
        .unwrap()
        .then((rs) => {
          notification.success({
            message: rs.message
          });
          onCreateSuccess?.();
        });
    } else {
      onUpdate({
        userId,
        ...data
      })
        .unwrap()
        .then((rs) => {
          notification.success({
            message: rs.message
          });
          onCreateSuccess?.();
        });
    }
  };
  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoadingCreate || isLoadingUpdate);
    }
  }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

  return (
    <Form
      labelAlign='right'
      labelCol={{
        flex: '180px'
      }}
      requiredMark={false}
      form={form}
      name=''
      onFinish={onFinish}
      layout='horizontal'
      validateMessages={validateMessages}
    >
      <Skeleton loading={isLoadingCreate}>
        <FormItem.FloatLabel<UserFormType> name='employeeId' label={usersMessages.employeeId}>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> name='fullName' label={usersMessages.fullName} rules={[{ required: true }]}>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> name='genderId' label={usersMessages.gender}>
          <SelectOptions service={MICROSERVICES.AUTH} optionTypeId={AUTH_OPTION_TYPES.GENDER} />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='shortName'
          label={usersMessages.shortName}
          rules={[{ required: true }]}
        >
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> name='birthday' label={usersMessages.birthday}>
          <DatePicker className='w-full' />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> label={usersMessages.userName} name='username'>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='password'
          label={usersMessages.newPassword}
          rules={[
            { required: true, message: messages.rulesMessages.passwords.requiredNew },
            { min: 6, message: messages.rulesMessages.passwords.atLeastCharacter }
          ]}
          hasFeedback
        >
          <Input.Password />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='confirmPassword'
          label={usersMessages.confirmPassword}
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: messages.rulesMessages.passwords.requiredConfirm },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(messages.rulesMessages.passwords.notMatch));
              }
            })
          ]}
        >
          <Input.Password />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> name='phoneNumber' label={usersMessages.phoneNumber}>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> label={usersMessages.email} name='email'>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='organizationUnitId'
          label={usersMessages.organize}
          rules={[{ required: true }]}
        >
          <SelectOrganizationUnits />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> name='userGroupId' label={usersMessages.group}>
          <SelectUserGroups allowClear />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> name='permissionIds' label={usersMessages.permissions}>
          <SelectPermissions mode='multiple' allowClear />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> name='statusId' label={usersMessages.status} rules={[{ required: true }]}>
          <SelectOptions service={MICROSERVICES.AUTH} optionTypeId={AUTH_OPTION_TYPES.USER_STATUS} />
        </FormItem.FloatLabel>
      </Skeleton>
    </Form>
  );
});
export default UserForm;
