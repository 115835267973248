import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import axiosClient from './axios-client';

const axiosBaseQuery: BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    onUploadProgress?: AxiosRequestConfig['onDownloadProgress'];
    headers?: AxiosRequestConfig['headers']; // Allow custom headers,
    _retry?: boolean;
    _ignoreRefresh?: boolean;
    _ignoreNotificationError?: boolean;
  },
  unknown,
  unknown
> = async (axiosConfig) => {
  try {
    const result = await axiosClient(axiosConfig);
    return { data: result };
  } catch (axiosError) {
    let err = axiosError as AxiosError;

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    };
  }
};

export default axiosBaseQuery;
