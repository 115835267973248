import { Form, FormInstance, Input, notification, Skeleton } from 'antd';
import { Rule } from 'antd/es/form';
import { FormItem } from 'components/common';
import { SelectPermissions } from 'components/permissions';
import { messages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useCreateRoleMutation, useGetRoleDetailQuery, useUpdateRoleMutation } from 'services';
import { CreateRoleDto } from 'types';
export type RoleFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  roleId?: number;
};

export type RoleFormRefProps = {
  form: FormInstance<CreateRoleDto>;
  isLoading: boolean;
};

type FormType = CreateRoleDto & {
  isHasParent?: boolean;
};
const RoleForm = forwardRef<RoleFormRefProps, RoleFormProps>(({ onChangeLoading, onCreateSuccess, roleId }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingCreate || isLoadingUpdate
  }));

  const [form] = Form.useForm();

  const { data: role, isLoading: isLoadingDetail } = useGetRoleDetailQuery(roleId!, {
    skip: !roleId
  });

  const [onCreate, { isLoading: isLoadingCreate }] = useCreateRoleMutation();
  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateRoleMutation();

  useEffect(() => {
    if (role && roleId) {
      form.setFieldsValue({
        ...role.data
      });
    }
  }, [role, roleId]);

  const onFinish = ({ isHasParent, ...values }: FormType) => {
    const data: CreateRoleDto = {
      ...values
    };
    if (!roleId) {
      onCreate(data)
        .unwrap()
        .then((rs) => {
          notification.success({
            message: rs.message
          });
          onCreateSuccess?.();
        });
    } else {
      onUpdate({
        roleId,
        ...data
      })
        .unwrap()
        .then((rs) => {
          notification.success({
            message: rs.message
          });
          onCreateSuccess?.();
        });
    }
  };
  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoadingCreate || isLoadingUpdate);
    }
  }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

  const validationRules: Partial<Record<keyof FormType, Rule[]>> = {
    name: [{ required: true }]
  };
  return (
    <Form
      labelAlign='right'
      labelCol={{
        flex: '180px'
      }}
      requiredMark={false}
      form={form}
      name=''
      onFinish={onFinish}
      layout='horizontal'
      validateMessages={validateMessages}
    >
      <Skeleton loading={isLoadingDetail}>
        <FormItem.FloatLabel<CreateRoleDto>
          label={messages.rolesMessages.name}
          name='name'
          rules={validationRules.name}
        >
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<CreateRoleDto>
          label={messages.rolesMessages.code}
          name='code'
          rules={validationRules.name}
        >
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<CreateRoleDto>
          label={messages.rolesMessages.description}
          name='description'
          rules={validationRules.name}
        >
          <Input.TextArea />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<CreateRoleDto> name='permissionIds' label={messages.rolesMessages.permissions}>
          <SelectPermissions mode='multiple' allowClear />
        </FormItem.FloatLabel>
      </Skeleton>
    </Form>
  );
});
export default RoleForm;
