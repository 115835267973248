import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateOrganizationUnitDto,
  FindAllDto,
  OrganizationUnitCompactDto,
  OrganizationUnitDto,
  ResponsePagingDto,
  ResponseSuccessDto,
  UpdateOrganizationUnitDto
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const organizationUnitsApi = createApi({
  reducerPath: 'organizationUnitsApi',
  tagTypes: ['organization_units', 'organization_units_detail'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getOrganizationUnits: builder.query<ResponsePagingDto<OrganizationUnitDto>, FindAllDto>({
      query: (params) => ({
        url: '/organization_units',
        method: 'get',
        params
      }),

      providesTags: (result) =>
        result && result.data.rows
          ? result.data.rows.map(({ organizationUnitId }) => ({
              type: 'organization_units',
              id: organizationUnitId
            }))
          : []
    }),

    getOrganizationUnitsOptions: builder.query<ResponsePagingDto<OrganizationUnitCompactDto>, FindAllDto>({
      query: (params) => ({
        url: '/organization_units/options',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows.push(...newItems.data.rows);
        } else currentCache.data.rows = newItems.data.rows;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      }
    }),

    getOrganizationUnitDetail: builder.query<ResponseSuccessDto<OrganizationUnitDto>, number>({
      query: (organizationUnitId) => ({ url: `/organization_units/${organizationUnitId}`, method: 'get' }),
      providesTags: (result) =>
        result ? [{ type: 'organization_units_detail', id: result.data.organizationUnitId }] : []
    }),

    addOrganizationUnit: builder.mutation<ResponseSuccessDto<OrganizationUnitDto>, CreateOrganizationUnitDto>({
      query: (newOrganizationUnit) => ({
        url: '/organization_units',
        method: 'post',
        data: newOrganizationUnit
      }),
      invalidatesTags: ['organization_units']
    }),
    updateOrganizationUnit: builder.mutation<ResponseSuccessDto<OrganizationUnitDto>, UpdateOrganizationUnitDto>({
      query: ({ organizationUnitId, ...updateOrganizationUnit }) => ({
        url: `/organization_units/${organizationUnitId}`,
        method: 'patch',
        data: updateOrganizationUnit
      }),
      invalidatesTags: (_result, _error, param) => [{ type: 'organization_units', id: param.organizationUnitId }]
    }),
    deleteOrganizationUnit: builder.mutation<void, number>({
      query: (organizationUnitId) => ({
        url: `/organization_units/${organizationUnitId}`,
        method: 'delete'
      })
    })
  })
});

export const {
  useLazyGetOrganizationUnitsOptionsQuery,
  useGetOrganizationUnitsOptionsQuery,
  useGetOrganizationUnitsQuery,
  useGetOrganizationUnitDetailQuery,
  useAddOrganizationUnitMutation,
  useUpdateOrganizationUnitMutation,
  useDeleteOrganizationUnitMutation
} = organizationUnitsApi;
