import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateUserDto,
  FindAllUserDto,
  ResponsePagingDto,
  ResponseSuccessDto,
  UpdateProfileDto,
  UpdateUserDto,
  UserDto,
  UserPermissionsDto
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['users', 'user_detail', 'user_permissions'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    //user-profile
    getUserProfile: builder.query<ResponseSuccessDto<UserDto>, void>({
      query: () => ({
        url: '/users/profile',
        method: 'get'
      }),
      providesTags: [{ type: 'user_detail', id: 'profile' }]
    }),
    updateUserProfile: builder.mutation<ResponseSuccessDto<UserDto>, UpdateProfileDto>({
      query: (updateData) => ({
        url: `/users/profile`,
        method: 'put',
        data: updateData
      }),
      invalidatesTags: [{ type: 'user_detail', id: 'profile' }]
    }),
    getUserProfilePermissions: builder.query<ResponseSuccessDto<UserPermissionsDto>, void>({
      query: () => ({ url: `/users/profile/permissions`, method: 'get' }),
      providesTags: (result) => (result ? [{ type: 'user_permissions', id: 'profile' }] : [])
    }),

    //user
    getUsers: builder.query<ResponsePagingDto<UserDto>, FindAllUserDto>({
      query: (params) => ({
        url: '/users',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows
          ? result.data.rows.map(({ userId }) => ({
              type: 'users',
              id: userId
            }))
          : []
    }),

    getUser: builder.query<ResponseSuccessDto<UserDto>, number>({
      query: (userId) => ({ url: `/users/${userId}`, method: 'get' }),
      providesTags: (result) => (result?.data ? [{ type: 'user_detail', id: result.data.userId }] : [])
    }),

    getUserPermissions: builder.query<ResponseSuccessDto<UserPermissionsDto>, number>({
      query: (userId) => ({ url: `/users/${userId}/permissions`, method: 'get' }),
      providesTags: (result) => (result?.data ? [{ type: 'user_permissions', id: result.data.userId }] : [])
    }),

    createUser: builder.mutation<ResponseSuccessDto<UserDto>, CreateUserDto>({
      query: (newUser) => ({
        url: '/users',
        method: 'post',
        data: newUser
      }),
      invalidatesTags: ['users']
    }),
    updateUser: builder.mutation<ResponseSuccessDto<UserDto>, UpdateUserDto>({
      query: ({ userId, ...updateUser }) => ({
        url: `/users/${userId}`,
        method: 'patch',
        data: updateUser
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'users', id: param.userId },
        { type: 'user_detail', id: param.userId }
      ]
    }),
    deleteUser: builder.mutation<void, number>({
      query: (userDeleteId) => ({
        url: `/users/${userDeleteId}`,
        method: 'delete'
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'users', id: param },
        { type: 'user_detail', id: param }
      ]
    })
  })
});

export const {
  useGetUserPermissionsQuery,
  useUpdateUserProfileMutation,
  useGetUserProfilePermissionsQuery,
  useGetUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUserProfileQuery
} = usersApi;
