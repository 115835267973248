import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { useOrganizationUnitsOptions } from 'hooks';

type SelectOrganizationUnitsProps = SelectProps;

const SelectOrganizationUnits = ({ ...props }: SelectOrganizationUnitsProps) => {
  const { organizationsUnits, handleLoadMore, handleSearch, isLoading } = useOrganizationUnitsOptions();
  return (
    <Select
      {...props}
      onLoadMore={() => {
        if (!isLoading) {
          handleLoadMore();
        }
      }}
      loading={isLoading}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={
        organizationsUnits.map((itm) => ({
          label: itm.name,
          value: itm.organizationUnitId
        })) || []
      }
    />
  );
};
export default SelectOrganizationUnits;
