export function capitalizeFirstLetter(text: string = '') {
  if (!text) {
    return text;
  }

  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function removeSpecialCharacters(keyword: string): string {
  if (!keyword) {
    return '';
  }

  return keyword
    .toLowerCase()
    .replace('đ', 'd')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .replace(/\\s+/g, ' ')
    .trim();
}

export function formatNumber(number: number, locale?: string): string {
  return new Intl.NumberFormat(locale ?? 'en-US').format(number);
}

export const stringToHslColor = (str?: string, s?: number, l?: number) => {
  if (!str) return '#000000';
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};
