import { Checkbox, Form, FormInstance, Input, notification, Skeleton } from 'antd';
import { Rule } from 'antd/es/form';
import { Select } from 'components/common';
import { useOrganizationUnitsOptions } from 'hooks';
import { messages, validateMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useAddOrganizationUnitMutation,
  useGetOrganizationUnitDetailQuery,
  useUpdateOrganizationUnitMutation
} from 'services/organization-units';
import { CreateOrganizationUnitDto } from 'types';
export type OrganizationUnitFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  organizationUnitId?: number;
};

export type OrganizationUnitFormRefProps = {
  form: FormInstance<CreateOrganizationUnitDto>;
  isLoading: boolean;
};

type FormType = CreateOrganizationUnitDto & {
  isHasParent?: boolean;
};
const OrganizationUnitForm = forwardRef<OrganizationUnitFormRefProps, OrganizationUnitFormProps>(
  ({ onChangeLoading, onCreateSuccess, organizationUnitId }, ref) => {
    useImperativeHandle(ref, () => ({
      form: form,
      isLoading: isLoadingCreate || isLoadingUpdate
    }));

    const [form] = Form.useForm();

    const { data: organizationUnit, isLoading: isLoadingDetail } = useGetOrganizationUnitDetailQuery(
      organizationUnitId!,
      {
        skip: !organizationUnitId
      }
    );
    const {
      organizationsUnits,
      handleLoadMore: handleLoadMoreOrganizationsUnits,
      handleSearch: handleSearchOrganizationsUnits,
      isLoading: isLoadingOrganizationUnits
    } = useOrganizationUnitsOptions();

    const [onCreate, { isLoading: isLoadingCreate }] = useAddOrganizationUnitMutation();
    const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateOrganizationUnitMutation();

    useEffect(() => {
      if (organizationUnit && organizationUnitId) {
        form.setFieldsValue({
          isHasParent: !!organizationUnit.data.parentId,
          ...organizationUnit.data
        });
      }
    }, [organizationUnit, organizationUnitId]);

    const onFinish = ({ isHasParent, ...values }: FormType) => {
      const data: CreateOrganizationUnitDto = {
        parentId: isHasParent ? values.parentId : undefined,
        ...values
      };
      if (!organizationUnitId) {
        onCreate(data)
          .unwrap()
          .then((rs) => {
            notification.success({
              message: rs.message
            });
            onCreateSuccess?.();
          });
      } else {
        onUpdate({
          organizationUnitId,
          ...data
        })
          .unwrap()
          .then((rs) => {
            notification.success({
              message: rs.message
            });
            onCreateSuccess?.();
          });
      }
    };
    useEffect(() => {
      if (onChangeLoading) {
        onChangeLoading(isLoadingCreate || isLoadingUpdate);
      }
    }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

    const isHasParent = Form.useWatch('isHasParent', form);

    const validationRules: Partial<Record<keyof FormType, Rule[]>> = {
      parentId: [{ required: isHasParent }],
      name: [{ required: true }],
      code: [{ required: true }]
    };
    return (
      <Form
        labelAlign='right'
        labelCol={{
          flex: '180px'
        }}
        requiredMark={false}
        form={form}
        name=''
        onFinish={onFinish}
        layout='horizontal'
        validateMessages={validateMessages}
      >
        <Skeleton loading={isLoadingDetail}>
          <Form.Item<FormType> label={<div />} name='isHasParent' valuePropName='checked' initialValue={true}>
            <Checkbox>{messages.organizationUnitsMessages.hasParent}</Checkbox>
          </Form.Item>
          <Form.Item<FormType>
            label={messages.organizationUnitsMessages.upperOrganizationUnit}
            rules={validationRules.parentId}
            name='parentId'
          >
            <Select
              onLoadMore={() => {
                if (!isLoadingOrganizationUnits) {
                  handleLoadMoreOrganizationsUnits();
                }
              }}
              loading={isLoadingOrganizationUnits}
              showSearch
              filterOption={false}
              onSearch={handleSearchOrganizationsUnits}
              options={
                organizationsUnits.map((itm) => ({
                  label: itm.name,
                  value: itm.organizationUnitId
                })) || []
              }
            />
          </Form.Item>
          <Form.Item<FormType>
            rules={validationRules.code}
            label={messages.organizationUnitsMessages.organizationUnitId}
            name='code'
          >
            <Input />
          </Form.Item>
          <Form.Item<FormType>
            rules={validationRules.name}
            label={messages.organizationUnitsMessages.organizationUnitName}
            name='name'
          >
            <Input />
          </Form.Item>
        </Skeleton>
      </Form>
    );
  }
);
export default OrganizationUnitForm;
