import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { useUserGroupsOptions } from 'hooks';

type SelectUserGroupsProps = SelectProps;

const SelectUserGroups = ({ ...props }: SelectUserGroupsProps) => {
  const { organizationsUnits, handleLoadMore, handleSearch, isLoading } = useUserGroupsOptions();
  return (
    <Select
      {...props}
      onLoadMore={() => {
        if (!isLoading) {
          handleLoadMore();
        }
      }}
      loading={isLoading}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={
        organizationsUnits.map((itm) => ({
          label: itm.name,
          value: itm.userGroupId
        })) || []
      }
    />
  );
};
export default SelectUserGroups;
