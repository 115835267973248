export const sidebarMenuMessages = {
  config: 'Cấu hình',
  usersAndPermissions: 'Người dùng & phân quyền',
  users: 'Người dùng',
  userGroups: 'Nhóm người dùng',
  permissions: 'Phân quyền',
  roles: 'Nhóm quyền',
  organizationUnit: 'Đơn vị/ phòng đội',
  customerSupportInformation: 'Thông tin hỗ trợ khách hàng'
};
