import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  authApi,
  customerSupportInformationApi,
  filesApi,
  optionsApi,
  organizationUnitsApi,
  permissionsApi,
  rolesApi,
  userGroupsApi,
  usersApi
} from 'services';
import { authSlice, usersSlice } from './features';

const combinedReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [organizationUnitsApi.reducerPath]: organizationUnitsApi.reducer,
  [userGroupsApi.reducerPath]: userGroupsApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [optionsApi.reducerPath]: optionsApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [customerSupportInformationApi.reducerPath]: customerSupportInformationApi.reducer,
  auth: authSlice,
  users: usersSlice
});
const rootReducer = (state: any, action: Action) => {
  if (action.type === 'RESET') {
    //We are calling this RESET, but call what you like!
    state = {};
  }
  return combinedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      usersApi.middleware,
      authApi.middleware,
      organizationUnitsApi.middleware,
      userGroupsApi.middleware,
      permissionsApi.middleware,
      optionsApi.middleware,
      filesApi.middleware,
      rolesApi.middleware,
      customerSupportInformationApi.middleware
    )
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
