export const ROUTE = {
  ALL: '*',
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_FORGOT_PASSWORD: '/verify-forgot-password',
  RESET_PASSWORD: '/reset-password',
  PROFILE: '/profile',
  USERS: '/users',
  ORGANIZATION_UNITS: '/organization-units',
  USER_GROUPS: '/user-groups',
  ROLES: '/roles',
  PERMISSIONS: '/permissions',
  CUSTOMER_SUPPORT_INFORMATION: '/customer-support-information'
};
