import {
  ContactsOutlined,
  DeploymentUnitOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  TableOutlined,
  TeamOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { messages } from 'messages';
import { ReactNode } from 'react';
import { ROUTE } from 'routes/constants';

export type MenuItem = {
  key: string;
  name: string | ReactNode;
  icon: ReactNode;
  href: string;
  children?: MenuItem[];
};
export const SidebarMenuItems: MenuItem[] = [
  {
    key: 'config',
    name: messages.sidebarMenuMessages.config,
    icon: <SettingOutlined />,
    href: ROUTE.ORGANIZATION_UNITS,
    children: [
      {
        key: 'organizationUnit',
        name: messages.sidebarMenuMessages.organizationUnit,
        icon: <TableOutlined />,
        href: ROUTE.ORGANIZATION_UNITS
      },
      {
        key: 'customerSupportInformation',
        name: messages.sidebarMenuMessages.customerSupportInformation,
        icon: <InfoCircleOutlined />,
        href: ROUTE.CUSTOMER_SUPPORT_INFORMATION
      }
    ]
  },
  {
    key: 'usersAndPermissions',
    name: messages.sidebarMenuMessages.usersAndPermissions,
    icon: <UserAddOutlined />,
    href: ROUTE.USERS,
    children: [
      {
        key: 'users',
        name: messages.sidebarMenuMessages.users,
        icon: <TeamOutlined />,
        href: ROUTE.USERS
      },
      {
        key: 'userGroups',
        name: messages.sidebarMenuMessages.userGroups,
        icon: <ContactsOutlined />,
        href: ROUTE.USER_GROUPS
      },
      {
        key: 'permissions',
        name: messages.sidebarMenuMessages.permissions,
        icon: <UserAddOutlined />,
        href: ROUTE.PERMISSIONS
      },
      {
        key: 'roles',
        name: messages.sidebarMenuMessages.roles,
        icon: <DeploymentUnitOutlined />,
        href: ROUTE.ROLES
      }
    ]
  }
];
