export const userGroupsMessages = {
  name: 'Nhóm người dùng',
  userGroupId: 'Mã quản lý',
  userGroupName: 'Tên ',
  userGroupQuantity: 'Số lượng',
  createNew: 'Tạo mới nhóm',
  organizationUnit: 'Đơn vị/ phòng đội',
  description: 'Mô tả',
  shortName: 'Tên thu gọn',
  permission: 'Phân quyền'
};
