import { useDispatch } from 'react-redux';
import { useLogoutMutation } from 'services';
import { LOCAL_STORAGE_KEY } from 'utils/constants';

export function useLogout() {
  const [logout, { isLoading }] = useLogoutMutation();
  const dispatch = useDispatch();

  const onLogoutHandle = async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    if (accessToken) {
      try {
        logout(accessToken).unwrap();
      } catch (err) {
        console.log(err);
      }
    }
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
    dispatch({
      type: 'RESET'
    });
    window.location.href = '/';
  };
  return {
    isLoading,
    onLogoutHandle
  };
}
