import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { useOptions } from 'hooks';
import { FindAllOptionsDto } from 'types';

type SelectOptionsProps = SelectProps & Pick<FindAllOptionsDto, 'optionTypeId' | 'service'>;

const SelectOptions = ({ optionTypeId, service, ...props }: SelectOptionsProps) => {
  const { data, handleSearch, isLoading } = useOptions({
    optionTypeId,
    service
  });
  return (
    <Select
      {...props}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      loading={isLoading}
      options={data.map((itm) => ({
        label: itm.name,
        value: itm.optionId
      }))}
    />
  );
};
export default SelectOptions;
