import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useGetUsersQuery } from 'services';
import { FindAllUserDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useUsersPaging({
  keyword,
  organizationUnitId,
  statusId,
  userGroupId
}: Omit<FindAllUserDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: usersResponse,
    error,
    isLoading
  } = useGetUsersQuery({
    pageIndex: pageIndex,
    pageSize,

    keyword,
    organizationUnitId,
    statusId,
    userGroupId
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    users: usersResponse?.data.rows,
    count: usersResponse ? usersResponse.data.count : 0,
    pageIndex,
    isLoading,
    error,
    handleChangePage
  };
}
