import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserGroupsQuery } from 'services/user-groups';
import { RootState } from 'store';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useUserGroupsPaging() {
  const dispatch = useDispatch();

  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [keyword, setKeyword] = useState('');
  const {
    data: userGroupsResponse,
    error,
    isLoading
  } = useGetUserGroupsQuery({
    keyword,
    pageIndex: pageIndex,
    pageSize
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  const handleSearch = (value: string) => {
    debouncedKeywordChange(value);
  };

  const debouncedKeywordChange = useCallback(
    debounce((keywordValue: string) => {
      setKeyword(keywordValue);
    }, 500),
    [setKeyword]
  );

  return {
    userGroups: userGroupsResponse?.data.rows,
    count: userGroupsResponse ? userGroupsResponse.data.count : 0,
    pageIndex,
    isLoading,
    error,

    handleChangePage,
    handleSearch,
    keyword
  };
}
